import React, {useRef, useState} from 'react';
import {Form} from "antd";
import "./snazzy-form-item.css"

import FormItemInput from "./FormItemInput";
import countries from "../../../../../app/data/countries";
import dayjs from "dayjs";
import FormItemLayout from "./FormItemLayout";
import ButtonAsync from "../buttons/ButtonAsync";

function FormItemQuestion({question}) {
    function log(...args) {

    }
    const snazzyForm = Form.useFormInstance()
    const [isFocused, setIsFocused] = useState(false);
    const getTextValue = (question, answerValue) => {
        log({
            question,
            answerValue
        })
        switch (question.answer.type) {
            case 'weight':
                return answerValue + ' kg'
            case 'height':
                return answerValue + ' cm'
            case 'euro':
                return "€" + answerValue.toLocaleString('en-us')
            case 'choice':
                let v1 = question.answer.choices.find(item => item.value === answerValue)
                if (v1) {
                    return v1.text
                }
                break
            case 'select':
                let v2 = question.answer.choices.find(item => item.value === answerValue)
                log({v2})
                if (v2) {
                    return v2.text
                }
                break
            case 'country' :
                let v3 = countries.find(item => item.alpha_2_code === answerValue)
                if (v3) {
                    return v3.en_short_name
                }
                break

            case 'nationality' :
                let v4 = countries.find(item => item.alpha_2_code === answerValue)
                if (v4) {
                    return v4.nationality
                }
                break
            case 'ddmmyyyy':
                let str = answerValue.split(' / ').reverse().join('-')
                const d = dayjs(str, "YYYY-MM-DD")
                if (!d.isValid()) {
                    return null
                }
                return d.format('D MMM YYYY')
            case 'multichoice':
                return answerValue.map(v => question.answer.choices.find(item => item.value === v).text).join(', ')
            case 'mmyyyy':

                if (answerValue) {
                    let str = `01 / ${answerValue}`.split(' / ').reverse().join('-')

                    const e = dayjs(str, "YYYY-MM-DD")
                    if (!e.isValid()) {
                        return null
                    }

                    return e.format('MMM YYYY')
                }
                return null

            case'lender':
            case 'mortgageProvider':
            case'phone':
            case 'int':
            case 'float':
            case 'text':
            case 'email':
            case 'address':
                return answerValue
            case 'textarea':
                return answerValue
            case 'agearray':
                if (answerValue.find(d => d === null)) {
                    return false
                }
                return answerValue.map(d => {
                    let str = d.split(' / ').reverse().join('-')
                    let dt = dayjs(str, 'YYYY-MM-DD')
                    if (!dt.isValid()) {
                        return null
                    }
                    return dt.format('D MMM YYYY')
                }).join(', ')
            default:
                return <div key={question.text}
                            style={{
                                padding        : 5,
                                backgroundColor: 'red'
                            }}>{question.answer.type}</div>
        }
    }
    const getDetailsFromForm = () => {
        if (!question) {
            return {
                label    : '',
                text     : 't',
                textValue: ''
            }
        }

        const answerValue = snazzyForm.getFieldValue(question.name)
        let hasValue = ![
            undefined,
            null
        ].includes(answerValue)

        if (Array.isArray(answerValue)) {
            hasValue = answerValue.filter(v => [
                null,
                undefined
            ].includes(v)).length === 0
        }
        let t = question.text
        let l = question.label
        if (typeof question.text === 'function') {
            t = question.text(snazzyForm)
        }
        if (typeof question.label === 'function') {
            l = question.label(snazzyForm)
        }
        let textValue = false

        if (hasValue) {
            textValue = getTextValue(question, answerValue)
        }
        else {

        }
        log({
            label    : l,
            text     : t,
            textValue: textValue,
            answerValue,
            hasValue,
            question
        })
        return {
            label    : l,
            text     : t,
            textValue: textValue
        }
    }
    const [state, setState] = useState(getDetailsFromForm())
    const refToFocus = useRef(null)

    const handleFocus = () => {

        setIsFocused(true);
    }
    const handleBlur = () => {
        let s = getDetailsFromForm()
        log({s})
        setState(s)
        setIsFocused(false);
    }
    const handleEdit = () => {
        setIsFocused(true);
        setTimeout(() => {
            if (refToFocus.current) {
                refToFocus.current.focus()
            }
        }, 100)
    }

    let open = isFocused || (!state.textValue && state.textValue !== 0)

    if (question?.type === 'button') {
        return <ButtonAsync onClick={question.onClick} icon={question.icon}> {question.label} </ButtonAsync>
    }
    if (question.name === 'mortgageType') {
        log({state})
    }
    return (<FormItemLayout title={state.label} description={state.text} handleEdit={handleEdit} disabled={question.disabled}
                            staticValue={state.textValue} open={open}>
            <FormItemInput open={open} refToFocus={refToFocus} onFocus={handleFocus} onBlur={handleBlur}
                           question={question}/>

        </FormItemLayout>

    );
}

export default FormItemQuestion;