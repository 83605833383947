import React, {useContext, useEffect, useRef, useState} from "react";
import getMortgage, {
    createUserMortgageUploads,
    onCreateUserMortgageDocuments,
    onCreateUserMortgageDynamicSection,
    onCreateUserMortgageQuery,
    onCreateUserMortgageUploads,
    onDeleteUserMortgageDynamicSection,
    onDeleteUserMortgageQuery,
    onUpdateUserApplicant,
    onUpdateUserApplicantAsset,
    onUpdateUserApplicantDebt,
    onUpdateUserApplicantEmployment,
    onUpdateUserApplicantFinancial,
    onUpdateUserApplicantIncome,
    onUpdateUserApplicantPersonal,
    onUpdateUserApplicantProperty,
    onUpdateUserApplicantTax,
    onUpdateUserMortgage,
    onUpdateUserMortgageDocuments,
    onUpdateUserMortgageDynamicSection,
    onUpdateUserMortgageProgress,
    onUpdateUserMortgagePropertyOnMortgage,
    onUpdateUserMortgageQuery,
    onUpdateUserMortgageRequirement,
    onUpdateUserMortgageSolicitor,
    onUpdateUserMortgageUploads,
    onUpdateUserApplicantEmploymentIncome,
    onCreateUserApplicantEmploymentIncome,
    onDeleteUserApplicantEmploymentIncome,
    onUpdateUserMortgageNeeds,
    onUpdateUserMortgageSubmission, onCreateUserMortgageSubmission
} from "../api/getMortgage";
import {
    userApplicantEmploymentIncomesByApplicantID,
    userApplicantActivitiesByApplicantID,
    userApplicantAssetsByApplicantID,
    userApplicantCurrentAccountsByApplicantID,
    userApplicantPropertiesByApplicantID,
    userMortgageDocumentsByMortgageID,
    userMortgageDynamicSectionsByMortgageID,
    userMortgageInvitationsByMortgageID,
    userMortgageProgressesByMortgageID,
    userMortgagePropertyOnMortgagesByMortgageID,
    userMortgageQueriesByMortgageID,
    userMortgageUploadsByMortgageID,
    userMortgageSubmissionsByMortgageID
} from "../api/getMoreItems";
import {performGQL, generateMutation} from "../../assets/functions/functions";
import {format, isValid} from "date-fns";
import {v4} from "uuid"
import {
    propertyTitle
} from "../../components/overlay-parts/apply/applicant/properties";
import {debtTitle} from "../../components/overlay-parts/apply/applicant/debts";
import {assetTitle} from "../../components/overlay-parts/apply/applicant/assets";
import {useNavigate} from "react-router-dom";
import {AuthCheckContext} from "../../../../app/auth/AuthCheck";
import {ApplicationStatuses, UploadStatuses} from "../../../../../models";
import {updateUserApplicantLastHere} from "../../../../../graphql/mutations";
import useSubscription from "../hooks/useSubscription";
import {
    onDeleteUserMortgageDocuments,
    onUpdateUserApplicantCurrentAccount,
    onUpdateUserMortgageInvitation
} from "../../../../../graphql/subscriptions";
import {userMortgageAppointmentsByMortgageID} from "../../../../../graphql/queries";

export const Mortgage = React.createContext()
export const extractTypeAndId = (str) => {
    //const pattern = /^\${(\w+)}\[(\w+)]$/;
    const pattern = /^(.*?)\[(\w+-\w+-\w+-\w+-\w+)]$/;
    if (str) {
        const match = str.match(pattern);
        if (match && match[1] && match[2]) {
            return {type: match[1], id: match[2]};
        }
    }
    return null;
}
export const setItemFromPath = (obj, propertyPath, newValue, methodToSet) => {
    let keys = propertyPath.split('.');
    let currentObj = obj;
    // Traverse the object until the second-to-last key
    if (propertyPath !== "") {
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i]
            const repeater1 = extractTypeAndId(key)
            if (repeater1) {
                currentObj = currentObj[repeater1.type].find(item => item.id === repeater1.id);
            } else {
                currentObj = currentObj[key];
            }
        }

        // Handle the last key separately to set the new value
        const lastKey = keys[keys.length - 1];
        switch (methodToSet) {
            case 'arrayAdd' :
                currentObj[lastKey].push(newValue)
                break
            case 'objectMerge' :
                const repeater2 = extractTypeAndId(lastKey)
                let indexedObj
                if (repeater2) {
                    indexedObj = currentObj[repeater2.type].find(item => item.id === repeater2.id);
                } else {
                    indexedObj = currentObj[lastKey]
                }
                let valKeys = Object.keys(newValue)
                for (let i = 0; i < valKeys.length; i++) {
                    indexedObj[valKeys[i]] = newValue[valKeys[i]]
                }
                break
            default:
                currentObj[lastKey] = newValue
        }
    } else {
        // operation on mortgage
        let valKeys = Object.keys(newValue)
        for (let i = 0; i < valKeys.length; i++) {
            currentObj[valKeys[i]] = newValue[valKeys[i]]
        }
    }

    return obj;
}

export const getItemFromPath = (obj, propertyPath) => {
    let currentObj = obj;
    if (propertyPath === '') {
        return currentObj
    }
    let keys = propertyPath.split('.');

    keys.forEach((key) => {
        let searchKey = key
        //propertyPath items may contain repeaters like applicant1.debts[adf12ed-23234...]
        let repeater = extractTypeAndId(key)

        if (repeater) {
            searchKey = repeater.type
        }
        if (!currentObj) {

            return null
        }
        if (currentObj.hasOwnProperty(searchKey)) {

            if (repeater) {
                currentObj = currentObj[searchKey].find(item => item.id === repeater.id);

            } else {

                currentObj = currentObj[searchKey];
            }
        } else {
            currentObj = null
        }
    })
    return currentObj;
}
export const correctValueForDb = (type, value) => {
    switch (type) {
        case 'ddmmyyyy' :
            let st1 = value.split(' / ').reverse().join('-')
            let d1 = new Date(st1)
            if (isValid(d1)) {
                return format(d1, "Y-MM-dd")
            }
            return null
        case 'mmyyyy' :
            let st2 = `01 / ${value}`.split(' / ').reverse().join('-')
            let d2 = new Date(st2)
            if (isValid(d2)) {
                return format(d2, "Y-MM-dd")
                //return d2.toISOString()
            }
            return null
        case 'agearray' :
            return value.map(d => {
                let st3 = d.split(' / ').reverse().join('-')
                let d3 = new Date(st3)
                if (isValid(d3)) {
                    return format(new Date(st3), "Y-MM-dd")
                }
                return null
            })
        default:
            return value
    }
}
export const correctValueForSys = (mortgage, question, value) => {
    if (question.answer.type === 'agearray') {
        // ensure the fields match the count
        if (!value) {
            let parts = question.target.split('.')
            parts.pop()
            parts.push('countDependents')
            let cD = getItemFromPath(mortgage, parts.join('.'))
            if (parseInt(cD) > 0) {
                value = Array(cD).fill(null)
            }
        } else {
            value = value.map(iso => format(new Date(iso), "dd / MM / Y"))
        }
    }
    if (value && value !== false) {
        if (question.answer.type === 'ddmmyyyy') {
            value = value.split("-").reverse().join(" / ")
        }
        if (question.answer.type === 'mmyyyy') {
            value = format(new Date(value), "MM / Y")
        }
    }

    return value
}
export const readAccessGroup = (mortgage, path) => {
    return [`${mortgage.id}-app1`, `${mortgage.id}-app2`]

    // if (path.startsWith('applicant1.')) {
    //     return [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
    // }
    // if (path.startsWith('applicant2.')) {
    //     return [`${mortgage.id}-app2`, `${mortgage.id}-app1`]
    // }
    // return [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
}
export const editAccessGroup = (mortgage, path) => {
    if (path.startsWith('applicant1.')) {
        return [`${mortgage.id}-app1`]
        // return [`${mortgage.id}-app1`, `${mortgage.id}-app2-edit`]
    }
    if (path.startsWith('applicant2.')) {
        return [`${mortgage.id}-app2`]
        //return [`${mortgage.id}-app2`, `${mortgage.id}-app1-edit`]
    }
    return [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
}
export const calcFormPercent = (mortgage, hook, applicant, index) => {
    const props = {applicant, mortgage}
    if (applicant) {
        props.you = mortgage[`applicant${applicant}`].strings
    }
    if (index) {
        props.index = index
    }
    const questions = hook(props)

    if (!questions.length) return 0
    const answers = {}
    const form = {
        getFieldValue: (key) => answers[key]
    }

    questions.forEach(question => {
        let value = getItemFromPath(mortgage, question.target)
        answers[question.name] = correctValueForSys(mortgage, question, value)
    })
    let requiredQuestions = questions.filter(question => {
        if (question?.hides) {
            if (question.hides(form)) {
                return false
            }
        }
        return !question.answer?.optional;
    })
    let ofWhichUnanswered = requiredQuestions.filter(question => {
        let v = form.getFieldValue(question.name)
        return [null, undefined].includes(v)
    })

    if (ofWhichUnanswered.length === 0) return 100
    let result = (1 - (ofWhichUnanswered.length / requiredQuestions.length)) * 100
    if (isNaN(result)) {

    }
    if (isNaN(parseInt(result))) return 0
    return result
}
function getModelName(path) {

    let repeaterPath = extractTypeAndId(path)
    if (repeaterPath) {

        path = repeaterPath.type
    }

    switch (path) {
        case "" :
            return {
                name: 'UserMortgage',
                stateIndex: 'mortgage'
            }
        case "needs":
            return {
                name: 'UserMortgageNeeds',
                stateIndex: 'mortgageNeeds'
            }
            case "queries":
            return {
                name: 'UserMortgageQuery',
                stateIndex: 'mortgageQueries'
            }
        case "properties" :
            return {
                name: 'UserMortgagePropertyOnMortgage',
                stateIndex: 'mortgageProperties'
            }
        case "invitations" :
            return {
                name: 'UserMortgageInvitation',
                stateIndex: 'mortgageInvitations'
            }
        case "progress" :
            return {
                name: 'UserMortgageProgress',
                stateIndex: 'mortgageProgress'
            }
        case "uploads" :
            return {
                name: 'UserMortgageUploads',
                stateIndex: 'mortgageUploads'
            }
        case "appointments" :
            return {
                name: 'UserMortgageAppointment',
                stateIndex: 'mortgageAppointments'
            }
        case "dynamicSections" :
            return {
                name: 'UserMortgageDynamicSection',
                stateIndex: 'mortgageDynamicSections'
            }
        case "submissions" :
            return {
                name: 'UserMortgageSubmission',
                stateIndex: 'mortgageSubmissions'
            }
        case "requirement" :
            return {
                name: 'UserMortgageRequirement',
                stateIndex: 'mortgageRequirement'
            }
        case "property" :
            return {
                name: 'UserMortgageProperty',
                stateIndex: 'mortgageProperties'
            }
        case "solicitor" :
            return {
                name: 'UserMortgageSolicitor',
                stateIndex: 'mortgageSolicitor'
            }
        case "UserMortgageDocuments":
            return {
                name: 'UserMortgageDocuments',
                stateIndex: 'mortgageDocuments'
            }
        case "applicant1" :
        case "applicant2" :
            return {
                name: 'UserApplicant',
                stateIndex: 'applicants'
            }
        case "applicant1.personal" :
        case "applicant2.personal" :
            return {
                name: 'UserApplicantPersonal',
                stateIndex: 'applicantPersonal'
            }
        case "applicant1.health" :
        case "applicant2.health" :
            return {
                name: 'UserApplicantHealth',
                stateIndex: 'applicantHealth'
            }
        case "applicant1.accounts" :
        case "applicant2.accounts" :
            return {
                name: 'UserApplicantCurrentAccount',
                stateIndex: 'applicantAccounts'
            }
        case "applicant1.income" :
        case "applicant2.income" :
            return {
                name: 'UserApplicantIncome',
                stateIndex: 'applicantIncome'
            }
        case "applicant1.financial" :
        case "applicant2.financial" :
            return {
                name: 'UserApplicantFinancial',
                stateIndex: 'applicantFinancial'
            }
        case "applicant1.employment" :
        case "applicant2.employment" :
            return {
                name: 'UserApplicantEmployment',
                stateIndex: 'applicantEmployment'
            }
        case "applicant1.tax" :
        case "applicant2.tax" :
            return {
                name: 'UserApplicantTax',
                stateIndex: 'applicantTax'
            }
        case "applicant1.assets":
        case "applicant2.assets":
            return {
                name: 'UserApplicantAsset',
                stateIndex: 'applicantAssets'
            }
        case "applicant1.debts":
        case "applicant2.debts":
            return {
                name: 'UserApplicantDebt',
                stateIndex: 'applicantDebts'
            }
        case "applicant1.properties":
        case "applicant2.properties":
            return {
                name: 'UserApplicantProperty',
                stateIndex: 'applicantProperties'
            }
        case "applicant1.activity":
        case "applicant2.activity":
            return {
                name: 'UserApplicantActivity',
                stateIndex: 'applicantActivity'
            }
        case "applicant1.employmentIncome":
        case "applicant2.employmentIncome":
            return {
                name: 'UserApplicantEmploymentIncome',
                stateIndex: 'applicantEmploymentIncome'
            }
        default:
    }
}

function MortgageProvider(props) {
    const userLastHereRow = useRef(false)
    const {user} = useContext(AuthCheckContext)
    /*
    This provider is an expanded, organised duplicate of the Mortgage model; values are same type as DB
     */
    const navigate = useNavigate()
    const [allSectionsLoaded, setAllSectionsLoaded] = useState(false)
    const [destructuredMortgage, setDestructuredMortgage] = useState({
        mortgage: {},
        mortgageSolicitor: {},
        mortgageRequirement: {},
        mortgageNeeds:{},
        mortgageSubmissions:[],
        mortgageProperties: [],
        mortgageUploads: [],
        mortgageDynamicSections: [],
        mortgageProgress: [],
        mortgageDocuments: [],
        mortgageQueries: [],
        mortgageInvitations: [],
        applicants: [],
        applicantAccounts: [],
        applicantIncome: [],
        applicantFinancial: [],
        applicantEmployment: [],
        applicantAssets: [],
        applicantDebts: [],
        applicantProperties: [],
        applicantTax: [],
        applicantHealth: [],
        applicantPersonal: [],
        applicantActivity: [],

    })
    const handleFetchedMortgage = (result) => {

        const fetchMoreItems = async (attribute, vars, operation) => {
            const {items, nextToken: newNextToken} = await performGQL(vars, operation);
            if (items.length) {
                setDestructuredMortgage(s => {
                    let copy = {...s}
                    let existingItems = s[attribute]
                    copy[attribute] = [...existingItems, ...items.filter(item => !item._deleted)]
                    return copy
                })
            }
            if (newNextToken) {
                await fetchMoreItems(attribute, {
                    ...vars,
                    nextToken: newNextToken
                }, operation)
            } else {
                if (attribute === 'mortgageProgress') {
                    setAllSectionsLoaded(true)
                }

            }
        }
        const setAndLoadRestInBackground = (attribute, responseItems, operation, variables, expandingFunction) => {
            if (responseItems) {
                let {items, nextToken} = responseItems
                //accept and set initial items, and if there's a nextToken, start process to retrieve all
                if (nextToken) {
                    fetchMoreItems(attribute, {...variables, nextToken}, operation)
                } else {
                    if (attribute === 'mortgageProgress') {
                        setAllSectionsLoaded(true)
                    }
                }
                const array = (items || []).filter(item => !item._deleted)
                if (expandingFunction?.m) {
                    return array.map(item => expandingFunction.m(item))
                }
                if (expandingFunction?.f) {
                    return array.filter(item => expandingFunction.f(item))
                }
                return array
            }
            return null
        }

        const applicantTax = [result.applicant1.tax, result.applicant2.tax]
        const applicantHealth = [result.applicant1.health, result.applicant2.health]
        const applicantPersonal = [result.applicant1.personal, result.applicant2.personal]
        const applicantIncome = [result.applicant1.income, result.applicant2.income]
        const applicantFinancial = [result.applicant1.financial, result.applicant2.financial]
        const applicantEmployment = [result.applicant1.employment, result.applicant2.employment]
        const mortgageRequirement = result.requirement
        const mortgageSolicitor = result.solicitor
        const mortgageNeeds = result.needs

        const mortgageSubmissions = setAndLoadRestInBackground('mortgageSubmissions', result.submissions, userMortgageSubmissionsByMortgageID, {mortgageID: result.id})
        const mortgageAppointments = setAndLoadRestInBackground('mortgageAppointments', result.appointments, userMortgageAppointmentsByMortgageID, {mortgageID: result.id})
        const mortgageInvitations = setAndLoadRestInBackground('mortgageInvitations', result.invitations, userMortgageInvitationsByMortgageID, {mortgageID: result.id})
        const mortgageProgress = setAndLoadRestInBackground('mortgageProgress', result.progress, userMortgageProgressesByMortgageID, {mortgageID: result.id})
        const mortgageDocuments = setAndLoadRestInBackground('mortgageDocuments', result.UserMortgageDocuments, userMortgageDocumentsByMortgageID, {mortgageID: result.id})
        const mortgageDynamicSections = setAndLoadRestInBackground('mortgageDynamicSections', result.dynamicSections, userMortgageDynamicSectionsByMortgageID, {mortgageID: result.id})
        const mortgageQueries = setAndLoadRestInBackground('mortgageQueries', result.queries, userMortgageQueriesByMortgageID, {mortgageID: result.id})
        const mortgageProperties = setAndLoadRestInBackground('mortgageProperties', result.properties, userMortgagePropertyOnMortgagesByMortgageID, {mortgageID: result.id})
        const mortgageUploads = setAndLoadRestInBackground('mortgageUploads', result.uploads, userMortgageUploadsByMortgageID, {mortgageID: result.id})
        const app1EmploymentIncome = setAndLoadRestInBackground('applicantEmploymentIncome', result.applicant1.employmentIncome, userApplicantEmploymentIncomesByApplicantID, {applicantID: result.applicant1.id})
        const app2EmploymentIncome = setAndLoadRestInBackground('applicantEmploymentIncome', result.applicant2.employmentIncome, userApplicantEmploymentIncomesByApplicantID, {applicantID: result.applicant2.id})
        const app1Accounts = setAndLoadRestInBackground('applicantAccounts', result.applicant1.accounts, userApplicantCurrentAccountsByApplicantID, {applicantID: result.applicant1.id})
        const app2Accounts = setAndLoadRestInBackground('applicantAccounts', result.applicant2.accounts, userApplicantCurrentAccountsByApplicantID, {applicantID: result.applicant2.id})
        const app1Activity = setAndLoadRestInBackground('applicantActivity', result.applicant1.activity, userApplicantActivitiesByApplicantID, {applicantID: result.applicant1.id})
        const app2Activity = setAndLoadRestInBackground('applicantActivity', result.applicant2.activity, userApplicantActivitiesByApplicantID, {applicantID: result.applicant2.id})
        const app1Assets = setAndLoadRestInBackground('applicantAssets', result.applicant1.assets, userApplicantAssetsByApplicantID, {applicantID: result.applicant1.id})
        const app2Assets = setAndLoadRestInBackground('applicantAssets', result.applicant2.assets, userApplicantAssetsByApplicantID, {applicantID: result.applicant2.id})
        const app1Debts = setAndLoadRestInBackground('applicantDebts', result.applicant1.debts, userApplicantAssetsByApplicantID, {applicantID: result.applicant1.id})
        const app2Debts = setAndLoadRestInBackground('applicantDebts', result.applicant2.debts, userApplicantAssetsByApplicantID, {applicantID: result.applicant2.id})
        const app1Properties = setAndLoadRestInBackground('applicantProperties', result.applicant1.properties, userApplicantPropertiesByApplicantID, {applicantID: result.applicant1.id})
        const app2Properties = setAndLoadRestInBackground('applicantProperties', result.applicant2.properties, userApplicantPropertiesByApplicantID, {applicantID: result.applicant2.id})
        const applicantDebts = [...app1Debts, ...app2Debts]
        const applicantAssets = [...app1Assets, ...app2Assets]
        const applicantProperties = [...app1Properties, ...app2Properties]
        const applicantActivity = [...app1Activity, ...app2Activity]
        const applicantAccounts = [...app1Accounts, ...app2Accounts]
        const applicantEmploymentIncome = [...app1EmploymentIncome, ...app2EmploymentIncome]
        // remove the children from the mortgage
        const {
            requirement,
            needs,
            properties,
            uploads,
            progress,
            applicant1,
            applicant2,
            queries,
            dynamicSections,
            UserMortgageDocuments,
            invitations,
            submissions,
            solicitor,
            ...mortgage
        } = result
        // remove repeating items from the applicant object
        const {
            personal: personal1,
            income: income1,
            financial: financial1,
            employment: employment1,
            tax: tax1,
            health: health1,
            assets: assets1,
            debts: debts1,
            properties: properties1,
            activity: activity1,
            accounts: accounts1,
            employmentIncome: employmentIncome1
        } = applicant1
        const {
            personal: personal2,
            income: income2,
            financial: financial2,
            employment: employment2,
            tax: tax2,
            health: health2,
            assets: assets2,
            debts: debts2,
            properties: properties2,
            activity: activity2,
            accounts: accounts2,
            employmentIncome: employmentIncome2
        } = applicant2
        const applicants = [applicant1, applicant2]
        setDestructuredMortgage({
            mortgage,
            applicants,
            applicantIncome,
            applicantFinancial,
            applicantEmployment,
            applicantEmploymentIncome,
            applicantAssets,
            applicantDebts,
            applicantProperties,
            applicantActivity,
            applicantAccounts,
            applicantTax,
            applicantHealth,
            applicantPersonal,
            mortgageRequirement,
            mortgageNeeds,
            mortgageSolicitor,
            mortgageProperties,
            mortgageUploads,
            mortgageProgress,
            mortgageInvitations,
            mortgageQueries,
            mortgageDynamicSections,
            mortgageDocuments,
            mortgageAppointments,
            mortgageSubmissions
        })
    }
    const applicantStrings = (applicant) => {
        const userIsApplicant = user.sub === applicant.subjectId
        const appName = applicant.firstName ? applicant.firstName : 'the other applicant'
        return {
            I: userIsApplicant ? 'I' : appName,
            you: userIsApplicant ? 'you' : appName,
            areYou: userIsApplicant ? 'are you' : 'is ' + appName,
            doYou: userIsApplicant ? 'do you' : 'does ' + appName,
            wereYou: userIsApplicant ? 'were you' : 'was ' + appName,
            haveYou: userIsApplicant ? 'have you' : 'has ' + appName,
            your: userIsApplicant ? 'your' : appName + `'s`,
            yourself: userIsApplicant ? 'yourself' : appName,
            name: appName
        }
    }
    const restructuredMortgage = () => {
        const reconstructApplicant = (n) => {
            const applicantId = destructuredMortgage.mortgage[`applicant${n}ID`]
            const app = destructuredMortgage.applicants.find(applicant => applicant.id === applicantId)
            const personal = destructuredMortgage.applicantPersonal.find(item => item.id === app.userApplicantPersonalId)
            const health = destructuredMortgage.applicantHealth.find(item=> item?.id === app.id)
            const income = destructuredMortgage.applicantIncome.find(item => item.id === app.userApplicantIncomeId)
            const financial = destructuredMortgage.applicantFinancial.find(item => item.id === app.userApplicantFinancialId)
            const employment = destructuredMortgage.applicantEmployment.find(item => item.id === app.userApplicantEmploymentId)
            const tax = destructuredMortgage.applicantTax.find(item => item.id === app.userApplicantTaxId)
            const assets = destructuredMortgage.applicantAssets.filter(item => item.applicantID === applicantId)
            const debts = destructuredMortgage.applicantDebts.filter(item => item.applicantID === applicantId)
            const properties = destructuredMortgage.applicantProperties.filter(item => item.applicantID === applicantId)
            const activity = destructuredMortgage.applicantActivity.filter(item => item.applicantID === applicantId)
            const accounts = destructuredMortgage.applicantAccounts.filter(item => item.applicantID === applicantId)
            const employmentIncome = destructuredMortgage.applicantEmploymentIncome.filter(item => item.applicantID === applicantId)
            return {
                ...app,
                personal,
                income,
                financial,
                employment,
                tax,
                health,
                employmentIncome: employmentIncome.map(it=>({...it, incomes: JSON.parse(it.incomes || '[]' )})),
                assets: assets.map(item => ({...item, friendlyName: assetTitle(item)})),
                debts: debts.map(item => ({...item, friendlyName: debtTitle(item)})),
                properties: properties.map(item => ({...item, friendlyName: propertyTitle(item)})),
                accounts,
                activity,
                strings: applicantStrings(app),
                fullName: `${app.firstName} ${app.surname}`,
                isCurrentUser: user.sub === app.subjectId,
                data: !!app.data ? JSON.parse(app.data) : {}
            }
        }
        let applicant1 = reconstructApplicant(1)
        let applicant2 = reconstructApplicant(2)
        let activeSubmission = null
        if (destructuredMortgage.mortgageSubmissions.length) {
            const submissions = destructuredMortgage.mortgageSubmissions.filter(a => !a.invalidatedReason).sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
            })
            activeSubmission = submissions.length ? submissions[0] : null
        }

        // were are all these items saved from ???????????????

        // copy activeSubmission to requirement
        const betterRequirement = {
            ...destructuredMortgage.mortgageRequirement,
            proposals: JSON.parse(destructuredMortgage.mortgageRequirement.proposals || '{}'),
        }
        if (activeSubmission){
            if (activeSubmission.lenderCode){
                betterRequirement.lenderCode = activeSubmission.lenderCode
            }
            if (activeSubmission.soft){
                betterRequirement.soft = activeSubmission.soft
            }
            if (activeSubmission.aipDate){
                betterRequirement.aipDate = activeSubmission.aipDate
            }
            if (activeSubmission.aipExpiryDate){
                betterRequirement.aipExpiryDate = activeSubmission.aipExpiryDate
            }
            if (activeSubmission.loanOfferDate){
                betterRequirement.loanOfferDate = activeSubmission.loanOfferDate
            }
            if (activeSubmission.loanOfferExpiryDate){
                betterRequirement.loanOfferExpiryDate = activeSubmission.loanOfferExpiryDate
            }
            if (activeSubmission.drawdownDate){
                betterRequirement.drawdownDate = activeSubmission.drawdownDate
            }
            if (activeSubmission.mortgageAccountNo){
                betterRequirement.mortgageAccountNo = activeSubmission.mortgageAccountNo
            }
           if (activeSubmission.aipPropertyReference){
                betterRequirement.propertyReference = activeSubmission.aipPropertyReference
           }
        }
        return {
            ...destructuredMortgage.mortgage,
            appointments: destructuredMortgage.mortgageAppointments,
            requirement: betterRequirement,
            needs: destructuredMortgage.mortgageNeeds,
            solicitor: destructuredMortgage.mortgageSolicitor,
            invitations: destructuredMortgage.mortgageInvitations,
            properties: destructuredMortgage.mortgageProperties,
            uploads: destructuredMortgage.mortgageUploads,
            progress: destructuredMortgage.mortgageProgress,
            queries: destructuredMortgage.mortgageQueries,
            dynamicSections: destructuredMortgage.mortgageDynamicSections,
            UserMortgageDocuments: destructuredMortgage.mortgageDocuments,
            submissions: destructuredMortgage.mortgageSubmissions,
            activeSubmission,
            applicant1,
            applicant2,
            loggedInApplicant: applicant1.isCurrentUser ? applicant1 : applicant2.isCurrentUser ? applicant2 : null,
            data: destructuredMortgage.mortgage.data ? JSON.parse(destructuredMortgage.mortgage.data) : {}
        }
    }
    const mortgage = allSectionsLoaded ? restructuredMortgage() : false

    // ON LOAD INITIALISATION
    const mortgageLoadedForUser = !user.isAdmin && allSectionsLoaded
    const lastSeenTimer = useRef(null)

    // SET TIMER TO UPDATE LAST SEEN & CHANGE SUSPENDED IF NEEDED
    useEffect(() => {
        if (!lastSeenTimer.current && mortgageLoadedForUser) {
            if (user.sub === mortgage.applicant1.subjectId) {
                if (mortgage.applicant1.lastHereTime) {
                    userLastHereRow.current = mortgage.applicant1.lastHereTime
                }
            }
            if (user.sub === mortgage.applicant2.subjectId) {
                if (mortgage.applicant2.lastHereTime) {
                    userLastHereRow.current = mortgage.applicant2.lastHereTime
                }
            }
            lastSeenTimer.current = async () => {
                try {
                    if (!!userLastHereRow.current) {
                        const row = await performGQL({
                            input: {
                                id: userLastHereRow.current.id,
                                time: new Date().toISOString(),
                                _version: userLastHereRow.current._version
                            }
                        }, updateUserApplicantLastHere)
                        userLastHereRow.current = row
                    }
                } catch (e) {
                    if (e.message === 'No current user'){

                        navigate('/sign-in')
                    }
                    console.log(e.message)
                }
            }
            lastSeenTimer.current()
            const intervalId = setInterval(lastSeenTimer.current, 1000 * 60)

            if ([ApplicationStatuses.SUSPENDED, ApplicationStatuses.DELAYED].includes(mortgage.applicationStatus)) {
                (async function () {
                    let update = {
                        target: 'applicationStatus',
                        value: ApplicationStatuses.ACTIVE
                    }
                    await optimisticMutate({update})
                })()
            }

            return () => {
                clearInterval(intervalId);
            }

        }
    }, [mortgageLoadedForUser])
    const fetchMortgage = async () => {
        try {
            const result = await getMortgage(props.mortgageId)

            handleFetchedMortgage(result)
        } catch (e) {
            if (e.message === 'No current user') {
                navigate('/sign-in')
            }
            console.log(e)
        }
    }
    async function reversibleCreate(creation) {
        // save object to the server before saving to the state

        const variables = {input: creation.pairs}
        const mutationName = 'Create' + creation.name
        const itemString = [...Object.keys(variables.input), 'createdAt', '_version', 'updatedAt', '_lastChangedAt'].join('\n')
        const query = generateMutation(mutationName, itemString)
        // on success replace the old object
        try {
            let result = await performGQL(variables, query)
            if (result) {
                setDestructuredMortgage(s => {
                    let copy = {...s}
                    state.update({id: result.id, stateIndex: creation.stateIndex, pairs: result}, copy)
                    return copy
                })
            }
            return result
        }
            // on failure delete the old object
        catch (e) {
            setDestructuredMortgage(s => {
                let copy = {...s}
                copy[creation.stateIndex] = copy[creation.stateIndex].filter(item => item.id !== creation.pairs.id)
                return copy
            })
        }
    }
    async function reversibleUpdate(update) {
        // save them to the server
        console.log({update})
        try {
            const variables = {
                input: {
                    id: update.id,
                    _version: update._version,
                    ...update.pairs
                }
            }
            const mutationName = 'Update' + update.name
            let itemString = Object.keys(variables.input)
            if (!itemString.includes('updatedAt')) {
                itemString.push('updatedAt')
            }
            if (!itemString.includes('_version')) {
                itemString.push('_version')
            }
            if (!itemString.includes('_lastChangedAt')) {
                itemString.push('_lastChangedAt')
            }
            if (!itemString.includes('createdAt')){
                itemString.push('createdAt')
            }
            if (!itemString.includes('mortgageID') && update.origModel.hasOwnProperty('mortgageID')) {
                itemString.push('mortgageID')
            }
            if (!itemString.includes('mortgageID') && update.origModel.hasOwnProperty('applicantID')) {
                itemString.push('applicantID')
            }
            if (!itemString.includes('adminAnswered') && update.origModel.hasOwnProperty('adminAnswered')) {
                itemString.push('adminAnswered')
            }
            if (!itemString.includes('userAnswered') && update.origModel.hasOwnProperty('userAnswered')) {
                itemString.push('userAnswered')
            }

            itemString = itemString.join('\n')

            const query = generateMutation(mutationName, itemString)
            const result = await performGQL(variables, query)

            if (result) {
                setDestructuredMortgage(s => {
                    let copy = {...s}
                    state.update({...update, pairs: result}, copy)
                    return copy
                })
            }
        } catch (e) {
            console.log({e})
            setDestructuredMortgage(s => {
                let copy = {...s}
                let {id, origPairs: pairs, stateIndex} = update
                state.update({id, pairs, stateIndex}, copy)
                return copy
            })
        }
    }
    async function reversibleDelete(deletion) {

        const mutationName = 'Delete' + deletion.name

        let itemString = `id\n_version\ncreatedAt\nupdatedAt\n_lastChangedAt`
        if (deletion.origItem.hasOwnProperty('applicantID')) {
            itemString += `\napplicantID`
        }
        const query = generateMutation(mutationName, itemString)
        const variables = {input: {id: deletion.origItem.id, _version: deletion.origItem._version}}

        try {
            await performGQL(variables, query)
        }
            // on failure put back the old object
        catch (e) {

            setDestructuredMortgage(s => {
                let copy = {...s}
                let { stateIndex} = deletion
                copy[stateIndex] = [...copy[stateIndex], deletion.origItem]
                return copy
            })

        }
    }

    const optimisticUpdate = (update) => {
        const modelsToUpdate = {}
        const promises = []

        const addFieldToModelsToUpdate = (field) => {
            // only updates the db and mortgage if the value has changed
            if (typeof field.target === "function") {
                field.target = field.target(mortgage)
            }

            // if passed pairs instead of values, call this function on each pair value
            if (field.hasOwnProperty('pairs')) {
                Object.keys(field.pairs).forEach(key => {
                    addFieldToModelsToUpdate({
                        target: `${field.target}.${key}`,
                        value: field.pairs[key]
                    })
                })
            } else {

                // if passed a value prop and full target
                let parts = field.target.split('.')
                let column = parts.pop()
                let path = parts.join('.')
                let modelName = 'mortgage.' + path


                let model = getItemFromPath(mortgage, path)

                let oldFieldValue = model[column]
                let newFieldValue = field?.type ? correctValueForDb(field.type, field.value) : field.value

                if (JSON.stringify(oldFieldValue) !== JSON.stringify(newFieldValue)) {

                    if (!modelsToUpdate.hasOwnProperty(modelName)) {
                        modelsToUpdate[modelName] = {
                            id: model.id,
                            pairs: {},
                            origPairs: {},
                            origModel: model,
                            path: path,
                            ...getModelName(path),
                            _version: model._version
                        }
                    }

                    modelsToUpdate[modelName].pairs[column] = newFieldValue
                    modelsToUpdate[modelName].origPairs[column] = oldFieldValue
                }

            }
        }

        if (Array.isArray(update)) {
            update.forEach((field, i) => {
                addFieldToModelsToUpdate(field)
            })
        } else {
            addFieldToModelsToUpdate(update)
        }
        // Immediately update the local mortgage and save to server
        Object.keys(modelsToUpdate).forEach(key => {
            let updateDetails = modelsToUpdate[key]
            promises.push(reversibleUpdate(updateDetails))
        })


        return {update: modelsToUpdate, promises}
    }
    const optimisticDelete = (deletion) => {
        const modelsToDelete = []
        const promises = []
        function addFieldToModelsToDelete(field) {
            if (typeof field.target === "function") {
                field.target = field.target(mortgage)
            }
            let origItem = getItemFromPath(mortgage, `${field.target}[${field.id}]`)

            let tempObject = {
                path: field.target,
                ...getModelName(field.target),
                origItem
            }

            modelsToDelete.push(tempObject)
        }
        if (Array.isArray(deletion)) {
            deletion.forEach((field, i) => {
                addFieldToModelsToDelete(field)
            })
        } else {
            addFieldToModelsToDelete(deletion)
        }
        // Immediately delete from local mortgage and delete from server
        modelsToDelete.forEach(deleteDetails => {

            promises.push(reversibleDelete(deleteDetails))

        })
        return {deletion: modelsToDelete, promises}
    }
    const optimisticCreate = (creation) => {
        // create temp object in the state
        // create on the server
        // on error, delete the temp object
        // on success, replace the temp object with the real one,

        const modelsToCreate = []
        const promises = []
        function addFieldToModelsToCreate(field) {
            if (typeof field.target === "function") {
                field.target = field.target(mortgage)
            }
            let path = field.target

            // create a new temporary object with fake id and add it to mortgage
            const tempObject = {
                pairs: {
                    id: v4(),
                    ...field.pairs,
                },
                path: path,
                ...getModelName(path)
            }
            if (tempObject.name?.startsWith('User')) {
                if (!tempObject.pairs.hasOwnProperty('readAccessGroup')) {
                    tempObject.pairs.readAccessGroup = readAccessGroup(mortgage, path)
                }
                if (!tempObject.pairs.hasOwnProperty('editAccessGroup')) {
                    tempObject.pairs.editAccessGroup = editAccessGroup(mortgage, path)
                }
            }

            modelsToCreate.push(tempObject)
        }
        if (Array.isArray(creation)) {
            creation.forEach((field, i) => {
                addFieldToModelsToCreate(field)
            })
        } else {
            addFieldToModelsToCreate(creation)
        }
        // Immediately update the local mortgage and save to server
        modelsToCreate.forEach(createDetails => {
            promises.push(reversibleCreate(createDetails))
        })
        return {
            create: modelsToCreate.map(item => ({
                ...item,
                pairs: {
                    ...item.pairs,
                    createdAt: (new Date()).toISOString(),
                    _version: 1
                }
            })), promises
        }
    }

    const state = {
        update: ({id, stateIndex, pairs}, prevState) => {
            if (Array.isArray(prevState[stateIndex])) {
                prevState[stateIndex] = prevState[stateIndex].map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            ...pairs
                        }
                    }
                    return item
                })
            } else {
                prevState[stateIndex] = {
                    ...prevState[stateIndex],
                    ...pairs
                }
            }
        },
        delete: (deletion, prevState) => {
            // can only delete from arrays
            prevState[deletion.stateIndex] = prevState[deletion.stateIndex].filter(item => item.id !== deletion.origItem.id)
        },
        create: (creation, prevState) => {
            prevState[creation.stateIndex] = [...prevState[creation.stateIndex], creation.pairs]
        }
    }
    const optimisticMutate = async (obj) => {

        // Whats the point here?
        // a) update the mortgage context with the new data
        // b) do multiple mutations at conveniently - possibility delete,update and create in one go, adding edit/read groups
        // c) send request to api
        // d) reverse what was done if fails
        // e) return
        let allPromises = []
        const result = {}
        if (obj?.update) {
            let {update, promises} = optimisticUpdate(obj.update)
            result.update = update
            promises.forEach(promise => allPromises.push(promise))
        }
        if (obj?.create) {
            let {create, promises} = optimisticCreate(obj.create)
            result.create = create
            promises.forEach(promise => allPromises.push(promise))
        }
        if (obj?.delete) {
            let {deletion, promises} = optimisticDelete(obj.delete)
            result.delete = deletion
            promises.forEach(promise => allPromises.push(promise))
        }
        // setItemFromPath(mortgageState, createDetails.path, fakeObject, 'arrayAdd')
        setDestructuredMortgage(m => {
            let copy = null
            if (result?.update) {
                copy = {...m}
                Object.keys(result.update).forEach(key => {
                    state.update(result.update[key], copy)
                })
            }
            if (result?.delete) {
                copy = copy ? copy : {...m}
                result.delete.forEach(deleteDetails => {
                    state.delete(deleteDetails, copy)
                })
            }
            if (result?.create) {
                copy = copy ? copy : {...m}
                result.create.forEach(createDetails => {
                    state.create(createDetails, copy)
                })
            }
            return copy ? copy : m
        })
        await Promise.all(allPromises)
        return result
    }

    useEffect(() => {
        if (!mortgage && !!user) {
            fetchMortgage()
        }
    }, [mortgage, user])

    // Upload Rows needed to be created temporarily in case upload fails
    const createTempUpload = (row) => {
        // must have an id already
        setDestructuredMortgage(s => {
            let copy = {...s}
            copy.mortgageUploads = [...copy.mortgageUploads, row]
            return copy

        })
    }
    const removeTempUpload = (row) => {
        setDestructuredMortgage(s => {
            let copy = {...row}
            copy.mortgageUploads = copy.mortgageUploads.filter(item => item.id !== row.id)
            return copy
        })
    }
    const confirmTempUpload = async (row) => {
        row.status = UploadStatuses.PENDING

        const result = await performGQL({input: row}, createUserMortgageUploads)
        setDestructuredMortgage(s => {
            let copy = {...s}
            copy.mortgageUploads = copy.mortgageUploads.map(item => {
                if (item.id === row.id) {
                    return result
                }
                return item
            })
            return copy
        })
    }

    // Subscriptions
    const setDestructuredMortgageFromSubscription = (updatingFunction, propertyInState) => {
        // updatingFunction is a function that expects the previous state and returns the next state
        setDestructuredMortgage(prevState => {
            const copy = {...prevState}
            const newVal = updatingFunction(prevState[propertyInState]);
            copy[propertyInState] = newVal
            return copy
        });
    }

    const mortgageValue = allSectionsLoaded ? {
        ...restructuredMortgage(),
        mutate: optimisticMutate,
        upload: {
            create: createTempUpload,
            remove: removeTempUpload,
            confirm: confirmTempUpload,
        }
    } : false
    let repairsRequired = false
    if (mortgageValue && !mortgageValue.applicant1.health){
        repairsRequired = true
    }
    return <Mortgage.Provider value={mortgageValue}>
        {props.children}
        {!!mortgageValue && <>
            {repairsRequired ?
                <Repairs  setDestructuredMortgage={setDestructuredMortgage} mortgage={mortgageValue}/> :
                <Subscriptions setDestructuredMortgageFromSubscription={setDestructuredMortgageFromSubscription}/>
            }
        </>}
    </Mortgage.Provider>
}

function Subscriptions({setDestructuredMortgageFromSubscription}) {
    useSubscription({
        subscription: {
            name: 'UserMortgageSubmission',
            onUpdate: onUpdateUserMortgageSubmission,
            onCreate: onCreateUserMortgageSubmission,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageSubmissions')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgage',
            onUpdate: onUpdateUserMortgage,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgage')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageProgress',
            onUpdate: onUpdateUserMortgageProgress,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageProgress')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageSolicitor',
            onUpdate: onUpdateUserMortgageSolicitor,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageSolicitor')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageRequirement',
            onUpdate: onUpdateUserMortgageRequirement,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageRequirement')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageNeeds',
            onUpdate: onUpdateUserMortgageNeeds,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageNeeds')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageUploads',
            onUpdate: onUpdateUserMortgageUploads,
            onCreate: onCreateUserMortgageUploads,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageUploads')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageQuery',
            onUpdate: onUpdateUserMortgageQuery,
            onDelete: onDeleteUserMortgageQuery,
            onCreate: onCreateUserMortgageQuery
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageQueries')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageDocuments',
            onUpdate: onUpdateUserMortgageDocuments,
            onCreate: onCreateUserMortgageDocuments,
            onDelete: onDeleteUserMortgageDocuments,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageDocuments')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageInvitation',
            onUpdate: onUpdateUserMortgageInvitation,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageInvitations')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgagePropertyOnMortgage',
            onUpdate: onUpdateUserMortgagePropertyOnMortgage,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageProperties')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageDynamicSection',
            onUpdate: onUpdateUserMortgageDynamicSection,
            onCreate: onCreateUserMortgageDynamicSection,
            onDelete: onDeleteUserMortgageDynamicSection
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'mortgageDynamicSections')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicant',
            onUpdate: onUpdateUserApplicant,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicants')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantPersonal',
            onUpdate: onUpdateUserApplicantPersonal,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantPersonal')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantIncome',
            onUpdate: onUpdateUserApplicantIncome,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantIncome')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantFinancial',
            onUpdate: onUpdateUserApplicantFinancial,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantFinancial')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantEmployment',
            onUpdate: onUpdateUserApplicantEmployment,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantEmployment')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantTax',
            onUpdate: onUpdateUserApplicantTax,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantTax')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantAsset',
            onUpdate: onUpdateUserApplicantAsset,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantAssets')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantEmploymentIncome',
            onUpdate: onUpdateUserApplicantEmploymentIncome,
            onCreate: onCreateUserApplicantEmploymentIncome,
            onDelete: onDeleteUserApplicantEmploymentIncome
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantEmploymentIncome')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantDebt',
            onUpdate: onUpdateUserApplicantDebt,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantDebts')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantProperty',
            onUpdate: onUpdateUserApplicantProperty,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantProperties')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicantCurrentAccount',
            onUpdate: onUpdateUserApplicantCurrentAccount,
        },
        setState: (updatingFunction) => setDestructuredMortgageFromSubscription(updatingFunction, 'applicantAccounts')
    })
}
function Repairs({setDestructuredMortgage, mortgage}){
    const createUserApplicantHealth = /* GraphQL */ `
        mutation CreateUserApplicantHealth(
            $input: CreateUserApplicantHealthInput!
            $condition: ModelUserApplicantHealthConditionInput
        ) {
            createUserApplicantHealth(input: $input, condition: $condition) {
                id
                weight
                height
                smoker
                everSmoked
                stoppedSmokingDate
                alcoholUnits
                dangerousActivities
                familyDeath
                diagnosedConditions
                diagnosedConditionsDetails
                prescribedMedication
                prescribedMedicationDetails
                hasGp
                gpName
                gpAddress
                gpLastVisit
                yearsWithGp
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
            }
        }
    `;
    const repairHealth = async ()=>{

        let promises = []
        if (!mortgage.applicant1.health) {
            promises.push(performGQL({
                input: {
                    id             : mortgage.applicant1.id,
                    readAccessGroup: [`${mortgage.id}-app2`],
                    editAccessGroup: [
                        `${mortgage.id}-app1`,
                        `${mortgage.applicant1.id}-appId`
                    ],
                }
            }, createUserApplicantHealth))
            promises.push(performGQL({
                input: {
                    id             : mortgage.applicant2.id,
                    readAccessGroup: [],
                    editAccessGroup: [
                        `${mortgage.id}-app1`,
                        `${mortgage.id}-app2`,
                        `${mortgage.applicant2.id}-appId`
                    ],
                }
            }, createUserApplicantHealth))
        }
       if (promises.length){
           let result = await Promise.all(promises)

           setDestructuredMortgage(s => {
               return {
                   ...s,
                   applicantHealth: result
               }
           })
       }


    }

    useEffect(() => {
        if (!mortgage.applicant1.health){
            repairHealth()
        }
    }, []);

    return null
}
export default MortgageProvider