import React, {useState} from 'react';
import {Col, Collapse, Row, Typography} from "antd";
import CardTitle from "../cards/CardTitle";
import CardDescription from "../cards/CardDescription";
import {EditOutlined} from "@ant-design/icons";
import {gray} from "@ant-design/colors";



function FormItemLayout({title, description, handleEdit, children, staticValue, open, disabled}) {

    return (

        <Row align="middle" gutter={15} className="w-full">
            <Col xs={24} sm={12}>
                    <span className="d-col w-100">
                        <CardTitle className={`dark:text-gray-400 snazzy-title ${open ? 'open':'open'}`}>{title}</CardTitle>

                          {/*<Collapse activeKey={open ? '1' : undefined} bordered={false} className="snazzy-collapse">*/}
                          {/*  <Collapse.Panel header={false} key="1" className="p-0 pb-1" style={{backgroundColor:'transparent'}}>*/}
                                <CardDescription>{description}</CardDescription>
                          {/*  </Collapse.Panel>*/}
                          {/*</Collapse>*/}

                    </span>
            </Col>
            <Col xs={24} sm={12}>

                {children}

                {!open && (
                    <div className="flex justify-end sm:justify-start w-full" style={{paddingLeft: 11, paddingTop: 9, paddingBottom:9}}>
                        <Typography.Text ellipsis className="text-cyan-700 dark:text-neutral-50">{staticValue}</Typography.Text>
                        {!disabled && <EditOutlined className="pl-1 sm:text-xs" style={{color:gray[4]}} onClick={handleEdit}/>}
                    </div>
                )
                }
            </Col>
        </Row>

    );
}

export default FormItemLayout;