import {performGQL, generateQuery} from "../../assets/functions/functions";
import {Auth} from "aws-amplify";
import {
    previousEmployerAddress,
    previousEmployerName, previousEmployerStartDate, previousEmploymentBasis, previousPositionHeld
} from "../../components/overlay-parts/apply/applicant/employmentIncome";
import {constructionCosts, siteOwned, siteValue} from "../../components/overlay-parts/apply/mortgage/property";
export const onCreateUserMortgageAppointment = /* GraphQL */ `
    subscription OnCreateUserMortgageAppointment(
        $filter: ModelSubscriptionUserMortgageAppointmentFilterInput
        $owner: String
    ) {
        onCreateUserMortgageAppointment(filter: $filter, owner: $owner) {
            id
            backendUserID
            appointmentType
            appointmentDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onDeleteUserMortgageAppointment = /* GraphQL */ `
    subscription OnDeleteUserMortgageAppointment(
        $filter: ModelSubscriptionUserMortgageAppointmentFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageAppointment(filter: $filter, owner: $owner) {
            id
            backendUserID
            appointmentType
            appointmentDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgage = /* GraphQL */ `
    subscription OnUpdateUserMortgage(
        $filter: ModelSubscriptionUserMortgageFilterInput
        $owner: String
    ) {
        onUpdateUserMortgage(filter: $filter, owner: $owner) {
            id
            twoApplicants
            mortgageType
            applicationType
            applicant1ID
            applicant2ID
            applicationStage
            applicationStatus
            qualification
            completedFlowItems
            data
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageRequirement = /* GraphQL */ `
    subscription OnUpdateUserMortgageRequirement(
        $filter: ModelSubscriptionUserMortgageRequirementFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageRequirement(filter: $filter, owner: $owner) {
            id
            applicationElsewhere
            loanRequired
            cohabiting
            loanTerm
            firstTimeBuyers
            depositSavings
            proposals
            depositGifts
            helpToBuyScheme
            helpToBuySchemeAmount
            firstHomeScheme
            firstHomeSchemeCouncil
            firstHomeSchemeType
            firstHomeSchemeAmount
            lenderCode
            lenderProduct
            receivingGifts
            drawdownDate
            aipExpiryDate
            loanOfferDate
            propertyReference
            mortgageAccountNo
            contactMethod
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgagePropertyOnMortgage = /* GraphQL */ `
    subscription OnUpdateUserMortgagePropertyOnMortgage(
        $filter: ModelSubscriptionUserMortgagePropertyOnMortgageFilterInput
        $owner: String
    ) {
        onUpdateUserMortgagePropertyOnMortgage(filter: $filter, owner: $owner) {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            origPurchasePrice
            yearOfPurchase
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            reinstatementValue
            valuationDate
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicant = /* GraphQL */ `
    subscription OnUpdateUserApplicant(
        $filter: ModelSubscriptionUserApplicantFilterInput
        $owner: String
    ) {
        onUpdateUserApplicant(filter: $filter, owner: $owner) {
            id
            subjectId
            identityID
            email
            mobile
            firstName
            surname
            isMale
            lastHere
            userApplicantPersonalId
            userApplicantIncomeId
            userApplicantFinancialId
            userApplicantEmploymentId
            userApplicantTaxId
            userApplicantLastHereId
            data
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserApplicantEmploymentIncome = /* GraphQL */ `
    subscription OnCreateUserApplicantEmploymentIncome(
        $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
        $owner: String
    ) {
        onCreateUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
            id
            applicantID
            employmentType
            employmentBasis
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            previousEmployerName
            previousEmployerAddress
            previousPositionHeld
            previousEmploymentBasis
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            publicSector
            nonEuroCurrency
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onUpdateUserApplicantEmploymentIncome = /* GraphQL */ `
    subscription OnUpdateUserApplicantEmploymentIncome(
        $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
            id
            applicantID
            employmentType
            employmentBasis
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            previousEmployerName
            previousEmployerAddress
            previousPositionHeld
            previousEmploymentBasis
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            publicSector
            nonEuroCurrency
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onDeleteUserApplicantEmploymentIncome = /* GraphQL */ `
    subscription OnDeleteUserApplicantEmploymentIncome(
        $filter: ModelSubscriptionUserApplicantEmploymentIncomeFilterInput
        $owner: String
    ) {
        onDeleteUserApplicantEmploymentIncome(filter: $filter, owner: $owner) {
            id
            applicantID
            employmentType
            employmentBasis
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            previousEmployerName
            previousEmployerAddress
            previousPositionHeld
            previousEmploymentBasis
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            nonEuroCurrency
            incomes
            publicSector
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onUpdateUserApplicantIncome = /* GraphQL */ `
    subscription OnUpdateUserApplicantIncome(
        $filter: ModelSubscriptionUserApplicantIncomeFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantIncome(filter: $filter, owner: $owner) {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            selfIncomeSource
            salariedInstitution
            incomeChecks
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantPersonal = /* GraphQL */ `
    subscription OnUpdateUserApplicantPersonal(
        $filter: ModelSubscriptionUserApplicantPersonalFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantPersonal(filter: $filter, owner: $owner) {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            placeOfBirth
            countryOfResidence
            countDependents
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantFinancial = /* GraphQL */ `
    subscription OnUpdateUserApplicantFinancial(
        $filter: ModelSubscriptionUserApplicantFinancialFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantFinancial(filter: $filter, owner: $owner) {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasProperties
            hasLoans
            hasSavings
            hasCurrentAccountSavings
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantEmployment = /* GraphQL */ `
    subscription OnUpdateUserApplicantEmployment(
        $filter: ModelSubscriptionUserApplicantEmploymentFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantEmployment(filter: $filter, owner: $owner) {
            id
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageUploads = /* GraphQL */ `
    subscription OnCreateUserMortgageUploads(
        $filter: ModelSubscriptionUserMortgageUploadsFilterInput
        $owner: String
    ) {
        onCreateUserMortgageUploads(filter: $filter, owner: $owner) {
            id
            contentType
            fileName
            fileSize
            fileExtension
            stage
            section
            index
            status
            mortgageID
            reason
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageUploads = /* GraphQL */ `
    subscription OnUpdateUserMortgageUploads(
        $filter: ModelSubscriptionUserMortgageUploadsFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageUploads(filter: $filter, owner: $owner) {
            id
            contentType
            fileName
            fileSize
            fileExtension
            stage
            section
            index
            status
            mortgageID
            reason
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageProgress = /* GraphQL */ `
    subscription OnCreateUserMortgageProgress(
        $filter: ModelSubscriptionUserMortgageProgressFilterInput
        $owner: String
    ) {
        onCreateUserMortgageProgress(filter: $filter, owner: $owner) {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            sectionChecked
            sectionCheckedBy
            submittedOn
            submittedBy
            expiresDate
            note
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageProgress = /* GraphQL */ `
    subscription OnUpdateUserMortgageProgress(
        $filter: ModelSubscriptionUserMortgageProgressFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageProgress(filter: $filter, owner: $owner) {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            sectionChecked
            sectionCheckedBy
            submittedOn
            submittedBy
            expiresDate
            note
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantTax = /* GraphQL */ `
    subscription OnUpdateUserApplicantTax(
        $filter: ModelSubscriptionUserApplicantTaxFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantTax(filter: $filter, owner: $owner) {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserApplicantAsset = /* GraphQL */ `
    subscription OnCreateUserApplicantAsset(
        $filter: ModelSubscriptionUserApplicantAssetFilterInput
        $owner: String
    ) {
        onCreateUserApplicantAsset(filter: $filter, owner: $owner) {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            applicantID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantAsset = /* GraphQL */ `
    subscription OnUpdateUserApplicantAsset(
        $filter: ModelSubscriptionUserApplicantAssetFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantAsset(filter: $filter, owner: $owner) {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            applicantID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserApplicantProperty = /* GraphQL */ `
    subscription OnCreateUserApplicantProperty(
        $filter: ModelSubscriptionUserApplicantPropertyFilterInput
        $owner: String
    ) {
        onCreateUserApplicantProperty(filter: $filter, owner: $owner) {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            outstanding
            monthlyRepayments
            remainingTerm
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantProperty = /* GraphQL */ `
    subscription OnUpdateUserApplicantProperty(
        $filter: ModelSubscriptionUserApplicantPropertyFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantProperty(filter: $filter, owner: $owner) {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            isMortgaged
            outstanding
            monthlyRepayments
            remainingTerm
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserApplicantDebt = /* GraphQL */ `
    subscription OnCreateUserApplicantDebt(
        $filter: ModelSubscriptionUserApplicantDebtFilterInput
        $owner: String
    ) {
        onCreateUserApplicantDebt(filter: $filter, owner: $owner) {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserApplicantDebt = /* GraphQL */ `
    subscription OnUpdateUserApplicantDebt(
        $filter: ModelSubscriptionUserApplicantDebtFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantDebt(filter: $filter, owner: $owner) {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageQuery = /* GraphQL */ `
    subscription OnCreateUserMortgageQuery(
        $filter: ModelSubscriptionUserMortgageQueryFilterInput
        $owner: String
    ) {
        onCreateUserMortgageQuery(filter: $filter, owner: $owner) {
            id
            mortgageID
            createdBy
            stage
            index
            referenceType
            referenceId
            section
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            adminAnswered
            userAnswered
            private
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageQuery = /* GraphQL */ `
    subscription OnUpdateUserMortgageQuery(
        $filter: ModelSubscriptionUserMortgageQueryFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageQuery(filter: $filter, owner: $owner) {
            id
            mortgageID
            createdBy
            stage
            referenceType
            referenceId
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            adminAnswered
            userAnswered
            private
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onDeleteUserMortgageQuery = /* GraphQL */ `
    subscription OnDeleteUserMortgageQuery(
        $filter: ModelSubscriptionUserMortgageQueryFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageQuery(filter: $filter, owner: $owner) {
            id
            mortgageID
            createdBy
            stage
            referenceType
            referenceId
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            adminAnswered
            userAnswered
            private
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageDynamicSection = /* GraphQL */ `
    subscription OnCreateUserMortgageDynamicSection(
        $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
        $owner: String
    ) {
        onCreateUserMortgageDynamicSection(filter: $filter, owner: $owner) {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionInstructions
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onDeleteUserMortgageDynamicSection = /* GraphQL */ `
    subscription OnDeleteUserMortgageDynamicSection(
        $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageDynamicSection(filter: $filter, owner: $owner) {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageDynamicSection = /* GraphQL */ `
    subscription OnUpdateUserMortgageDynamicSection(
        $filter: ModelSubscriptionUserMortgageDynamicSectionFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageDynamicSection(filter: $filter, owner: $owner) {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionInstructions
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageDocuments = /* GraphQL */ `
    subscription OnCreateUserMortgageDocuments(
        $filter: ModelSubscriptionUserMortgageDocumentsFilterInput
        $owner: String
    ) {
        onCreateUserMortgageDocuments(filter: $filter, owner: $owner) {
            id
            title
            description
            folder
            fileName
            identityID
            identityID2
            fileExtension
            isDynamic
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageDocuments = /* GraphQL */ `
    subscription OnUpdateUserMortgageDocuments(
        $filter: ModelSubscriptionUserMortgageDocumentsFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageDocuments(filter: $filter, owner: $owner) {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            mortgageID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageSolicitor = /* GraphQL */ `
    subscription OnUpdateUserMortgageSolicitor(
        $filter: ModelSubscriptionUserMortgageSolicitorFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageSolicitor(filter: $filter, owner: $owner) {
            id
            firmName
            firmAddress
            contactName
            contactEmail
            contactPhone
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageInvitation = /* GraphQL */ `
    subscription OnUpdateUserMortgageInvitation(
        $filter: ModelSubscriptionUserMortgageInvitationFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageInvitation(filter: $filter, owner: $owner) {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const createUserMortgageUploads = /* GraphQL */ `
    mutation CreateUserMortgageUploads(
        $input: CreateUserMortgageUploadsInput!
        $condition: ModelUserMortgageUploadsConditionInput
    ) {
        createUserMortgageUploads(input: $input, condition: $condition) {
            id
            contentType
            fileName
            fileSize
            fileExtension
            stage
            section
            index
            status
            mortgageID
            identityID
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageNeeds = /* GraphQL */ `
    subscription OnUpdateUserMortgageNeeds(
        $filter: ModelSubscriptionUserMortgageNeedsFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageNeeds(filter: $filter, owner: $owner) {
            id
            circumstanceChanges
            circumstanceChangesDetails
            healthIssues
            healthIssuesDetails
            policeRecord
            policeRecordDetails
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
export const onCreateUserMortgageSubmission = /* GraphQL */ `
    subscription OnCreateUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onCreateUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            soft
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            loanOfferDate
            loanOfferExpiryDate
            drawdownDate
            completedFlowItems
            reinstatementValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            reinstatement2Value
            valuation2Value
            surveyRequired
            surveyDate
            mortgageAccountNo
            mortgageID
            invalidatedReason
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageSubmission = /* GraphQL */ `
    subscription OnUpdateUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            soft
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            loanOfferDate
            loanOfferExpiryDate
            drawdownDate
            completedFlowItems
            reinstatementValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            reinstatement2Value
            valuation2Value
            surveyRequired
            surveyDate
            mortgageAccountNo
            mortgageID
            invalidatedReason
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onDeleteUserMortgageSubmission = /* GraphQL */ `
    subscription OnDeleteUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            soft
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            loanOfferDate
            loanOfferExpiryDate
            drawdownDate
            completedFlowItems
            reinstatementValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            reinstatement2Value
            valuation2Value
            surveyRequired
            surveyDate
            mortgageAccountNo
            mortgageID
            invalidatedReason
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
const getUserMortgageForApp2 = /* GraphQL */ `
    query GetUserMortgage($id: ID!) {
        getUserMortgage(id: $id) {
            id
            twoApplicants
            mortgageType
            applicationType
            applicant1ID
            applicant2ID
            submissions {
                items {
                    id
                    lenderCode
                    provisionalProduct
                    provisionalRateId
                    provisionalRate
                    provisionalSecondaryProduct
                    provisionalSecondaryRate
                    provisionalSecondaryRateId
                    soft
                    aipMaxLoan
                    aipPropertyReference
                    aipExpiryDate
                    aipDate
                    loanOfferDate
                    loanOfferExpiryDate
                    drawdownDate
                    completedFlowItems
                    reinstatementValue
                    valuationInstructed
                    valuationDate
                    valuationExpiryDate
                    anotherValuationNeeded
                    valuation2Instructed
                    valuation2Date
                    valuation2ExpiryDate
                    reinstatement2Value
                    valuation2Value
                    surveyRequired
                    surveyDate
                    mortgageAccountNo
                    mortgageID
                    invalidatedReason
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
                __typename
            }
            appointments {
                items {
                    id
                    backendUserID
                    appointmentType
                    appointmentDate
                    mortgageID
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    __typename
                }
                nextToken
                startedAt
                __typename
            }
            requirement {
                id
                applicationElsewhere
                loanRequired
                cohabiting
                loanTerm
                firstTimeBuyers
                depositSavings
                depositGifts
                helpToBuyScheme
                helpToBuySchemeAmount
                firstHomeScheme
                firstHomeSchemeCouncil
                firstHomeSchemeType
                firstHomeSchemeAmount
                lenderCode
                lenderProduct
                rateId
                receivingGifts
                drawdownDate
                aipExpiryDate
                loanOfferDate
                propertyReference
                mortgageAccountNo
                contactMethod
                proposals
                soft
                createdAt
                updatedAt
                _version
                _lastChangedAt
                owner
            }
            needs {
                id
                circumstanceChanges
                circumstanceChangesDetails
                healthIssues
                healthIssuesDetails
                policeRecord
                policeRecordDetails
                createdAt
                updatedAt
                _version
            }
            properties {
                items {
                    id
                    addressKnown
                    address
                    propertyType
                    buildType
                    newlyBuilt
                    bedrooms
                    bathrooms
                    berScore
                    propertyValue
                    propertyPrice
                    origPurchasePrice
                    yearOfPurchase
                    residingHere
                    adultsBoarding
                    adultsBoardingCount
                    isRented
                    monthlyRent
                    isMortgaged
                    remainingTerm
                    lender
                    accountNumber
                    monthlyPayment
                    currentRate
                    outstanding
                    reinstatementValue
                    valuationDate
                    valuationInstructed
                    siteOwned
                    siteValue
                    constructionCosts
                    constructionStarted
                    mortgageID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            uploads {
                items {
                    id
                    contentType
                    fileName
                    fileSize
                    fileExtension
                    stage
                    section
                    index
                    status
                    mortgageID
                    reason
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            progress {
                items {
                    id
                    applicationStage
                    sectionType
                    sectionName
                    sectionIndex
                    percent
                    mortgageID
                    percentUpdatedAt
                    alertTitle
                    alertBody
                    alertAuthor
                    alertDismissed
                    alertDone
                    alertPending
                    sectionChecked
                    sectionCheckedBy
                    submittedOn
                    submittedBy
                    expiresDate
                    note
                    lastDocumentDate
                    lastDocumentDateDismissed
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            applicant1 {
                id
                subjectId
                identityID
                email
                mobile
                firstName
                surname
                isMale
                lastHere
                userApplicantPersonalId
                userApplicantIncomeId
                userApplicantFinancialId
                userApplicantEmploymentId
                userApplicantTaxId
                data
                health {
                    id
                    weight
                    height
                    smoker
                    everSmoked
                    stoppedSmokingDate
                    alcoholUnits
                    dangerousActivities
                    familyDeath
                    diagnosedConditions
                    diagnosedConditionsDetails
                    prescribedMedication
                    prescribedMedicationDetails
                    hasGp
                    gpName
                    gpAddress
                    gpLastVisit
                    gpLastVisitYearsAgo
                    yearsWithGp
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                accounts {
                    items {
                        id
                        joint
                        isClosed
                        institution
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                personal {
                    id
                    title
                    dateOfBirth
                    maritalStatus
                    maidenName
                    mothersMaidenName
                    nationality
                    countryOfBirth
                    placeOfBirth
                    countryOfResidence
                    countDependents
                    dependentDOBs
                    accommodationSituation
                    accommodationSituationOther
                    addressCurrent
                    addressCurrentYears
                    addressCurrentStartDate
                    addressPrevious
                    irishResidenceStartDate
                    otherCountryOfResidenceInPast3Years
                    createdAt
                    updatedAt
                    _version
                }
                income {
                    id
                    grossBasic
                    hasOvertime
                    overtime
                    hasBonus
                    bonus
                    hasCommission
                    commission
                    hasOther
                    other
                    otherSource
                    otherSourceDetails
                    nonEuroCurrency
                    maintenance
                    welfare
                    secondjob
                    rentalIncome
                    dividends
                    selfIncomeSource
                    salariedInstitution
                    incomeChecks
                    createdAt
                    updatedAt
                    _version
                }
                financial {
                    id
                    hasAccommodationCosts
                    monthlyAccommodationCosts
                    hasChildcareCosts
                    contributeToRelatives
                    negativeCredit
                    hasAssets
                    hasDebts
                    hasIncome
                    hasProperties
                    hasLoans
                    hasSavings
                    hasCurrentAccountSavings
                    paysMaintenance
                    maintenanceCosts
                    monthlyChildcareCosts
                    monthlySavings
                    createdAt
                    updatedAt
                    _version
                }
                employment {
                    id
                    employmentType
                    currentEmployerName
                    currentEmployerAddress
                    currentEmployerPhone
                    currentEmployerYearsThere
                    currentEmployerContact
                    previousEmployerName
                    previousEmployerAddress
                    previousEmployerYearsThere
                    positionHeld
                    nameOfBusiness
                    natureOfBusiness
                    businessAddress
                    accountantName
                    accountantAddress
                    accountantPhone
                    businessEstablished
                    employmentBasis
                    limitedCompany
                    isDirector
                    percentageOfShares
                    currentEmployerStartDate
                    createdAt
                    updatedAt
                    _version
                }
                employmentIncome {
                    items {
                        id
                        applicantID
                        employmentType
                        employmentBasis
                        positionHeld
                        currentEmployerName
                        currentEmployerAddress
                        currentEmployerPhone
                        currentEmployerContact
                        currentEmployerStartDate
                        firstJob
                        previousEmployerName
                        previousEmployerAddress
                        previousPositionHeld
                        previousEmploymentBasis
                        previousEmployerStartDate
                        previousEmployerEndDate
                        nameOfBusiness
                        natureOfBusiness
                        businessAddress
                        accountantName
                        accountantAddress
                        accountantPhone
                        businessEstablished
                        limitedCompany
                        isDirector
                        percentageOfShares
                        selfIncomeSource
                        grossBasic
                        nonEuroCurrency
                        incomes
                        publicSector
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                tax {
                    id
                    irishTaxResident
                    ppsNumber
                    usCitizen
                    usTaxId
                    otherTaxResident
                    otherTaxCountry
                    otherTaxId
                    createdAt
                    updatedAt
                    _version
                }
                assets {
                    items {
                        id
                        assetType
                        joint
                        isClosed
                        institution
                        description
                        balance
                        increasesFrequency
                        increases
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                properties {
                    items {
                        id
                        propertyType
                        address
                        estimatedValue
                        isRented
                        rentPerAnnum
                        isMortgaged
                        outstanding
                        monthlyRepayments
                        remainingTerm
                        lender
                        inArrears
                        arrears
                        applicantID
                        bedrooms
                        bathrooms
                        isRestructured
                        observeMID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                debts {
                    items {
                        id
                        debtType
                        lender
                        outstanding
                        monthlyPayment
                        creditLimit
                        inArrears
                        arrears
                        endDate
                        clearedBeforeMortgage
                        applicantID
                        accountNumber
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                activity {
                    items {
                        id
                        actions
                        actionedOn
                        actionedBy
                        applicantID
                        userAgent
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _deleted
                        _version
                    }
                    nextToken
                }
                createdAt
                updatedAt
                _version
                _lastChangedAt
                owner
            }
            applicant2 {
                id
                subjectId
                identityID
                email
                mobile
                firstName
                surname
                isMale
                lastHere
                userApplicantPersonalId
                userApplicantIncomeId
                userApplicantFinancialId
                userApplicantEmploymentId
                userApplicantTaxId
                userApplicantLastHereId
                data
                employmentIncome {
                    items {
                        id
                        applicantID
                        employmentType
                        employmentBasis
                        positionHeld
                        currentEmployerName
                        currentEmployerAddress
                        currentEmployerPhone
                        currentEmployerContact
                        currentEmployerStartDate
                        firstJob
                        previousEmployerName
                        previousEmployerAddress
                        previousPositionHeld
                        previousEmploymentBasis
                        previousEmployerStartDate
                        previousEmployerEndDate
                        nameOfBusiness
                        natureOfBusiness
                        businessAddress
                        accountantName
                        accountantAddress
                        accountantPhone
                        businessEstablished
                        limitedCompany
                        isDirector
                        percentageOfShares
                        selfIncomeSource
                        grossBasic
                        nonEuroCurrency
                        incomes
                        publicSector
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                health {
                    id
                    weight
                    height
                    smoker
                    everSmoked
                    stoppedSmokingDate
                    alcoholUnits
                    dangerousActivities
                    familyDeath
                    diagnosedConditions
                    diagnosedConditionsDetails
                    prescribedMedication
                    prescribedMedicationDetails
                    hasGp
                    gpName
                    gpAddress
                    gpLastVisit
                    gpLastVisitYearsAgo
                    yearsWithGp
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                accounts {
                    items {
                        id
                        joint
                        isClosed
                        institution
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                personal {
                    id
                    title
                    dateOfBirth
                    maritalStatus
                    maidenName
                    mothersMaidenName
                    nationality
                    countryOfBirth
                    placeOfBirth
                    countryOfResidence
                    countDependents
                    dependentDOBs
                    accommodationSituation
                    accommodationSituationOther
                    addressCurrent
                    addressCurrentYears
                    addressCurrentStartDate
                    addressPrevious
                    irishResidenceStartDate
                    otherCountryOfResidenceInPast3Years
                    createdAt
                    updatedAt
                    _version
                }
                income {
                    id
                    grossBasic
                    hasOvertime
                    overtime
                    hasBonus
                    bonus
                    hasCommission
                    commission
                    hasOther
                    other
                    otherSource
                    otherSourceDetails
                    nonEuroCurrency
                    maintenance
                    welfare
                    secondjob
                    rentalIncome
                    dividends
                    selfIncomeSource
                    salariedInstitution
                    incomeChecks
                    createdAt
                    updatedAt
                    _version
                }
                financial {
                    id
                    hasAccommodationCosts
                    monthlyAccommodationCosts
                    hasChildcareCosts
                    contributeToRelatives
                    negativeCredit
                    hasAssets
                    hasDebts
                    hasIncome
                    hasProperties
                    hasLoans
                    hasSavings
                    hasCurrentAccountSavings
                    paysMaintenance
                    maintenanceCosts
                    monthlyChildcareCosts
                    monthlySavings
                    createdAt
                    updatedAt
                    _version
                }
                employment {
                    id
                    employmentType
                    currentEmployerName
                    currentEmployerAddress
                    currentEmployerPhone
                    currentEmployerYearsThere
                    currentEmployerContact
                    previousEmployerName
                    previousEmployerAddress
                    previousEmployerYearsThere
                    positionHeld
                    nameOfBusiness
                    natureOfBusiness
                    businessAddress
                    accountantName
                    accountantAddress
                    accountantPhone
                    businessEstablished
                    employmentBasis
                    limitedCompany
                    isDirector
                    percentageOfShares
                    currentEmployerStartDate
                    createdAt
                    updatedAt
                    _version
                }
                tax {
                    id
                    irishTaxResident
                    ppsNumber
                    usCitizen
                    usTaxId
                    otherTaxResident
                    otherTaxCountry
                    otherTaxId
                    createdAt
                    updatedAt
                    _version
                }
                assets {
                    items {
                        id
                        joint
                        isClosed
                        assetType
                        institution
                        description
                        balance
                        increasesFrequency
                        increases
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                properties {
                    items {
                        id
                        propertyType
                        address
                        estimatedValue
                        isRented
                        rentPerAnnum
                        isMortgaged
                        outstanding
                        monthlyRepayments
                        remainingTerm
                        lender
                        inArrears
                        arrears
                        applicantID
                        bedrooms
                        bathrooms
                        isRestructured
                        observeMID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                debts {
                    items {
                        id
                        debtType
                        lender
                        outstanding
                        monthlyPayment
                        creditLimit
                        inArrears
                        arrears
                        endDate
                        clearedBeforeMortgage
                        applicantID
                        accountNumber
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                activity {
                    items {
                        id
                        actions
                        actionedOn
                        actionedBy
                        applicantID
                        userAgent
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _deleted
                    }
                    nextToken
                }
                lastHereTime{
                    id
                    time
                    _version
                }
                createdAt
                updatedAt
                _version
                _lastChangedAt
                owner
            }
            applicationStage
            applicationStatus
            queries {
                items {
                    id
                    mortgageID
                    referenceType
                    referenceId
                    section
                    index
                    title
                    date
                    stage
                    description
                    amount
                    query
                    userAnswer
                    userAnswerOn
                    userAnswerBy
                    adminAnswered
                    userAnswered
                    private
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            solicitor {
                id
                firmName
                firmAddress
                contactName
                contactEmail
                contactPhone
                createdAt
                updatedAt
                _version
                _lastChangedAt
                owner
            }
            dynamicSections {
                items {
                    id
                    sectionStage
                    sectionType
                    sectionName
                    sectionIndex
                    sectionTitle
                    sectionInstructions
                    mortgageID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            invitations {
                items {
                    id
                    mortgageID
                    applicant2ID
                    firstName
                    email
                    inviter
                    inviterFirstName
                    sent
                    accepted
                    invalidated
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            advisorID
            completedFlowItems
            qualification
            UserMortgageDocuments {
                items {
                    id
                    title
                    description
                    folder
                    fileName
                    fileExtension
                    isDynamic
                    identityID
                    identityID2
                    mortgageID
                    stage
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            userMortgageRequirementId
            userMortgageNeedsId
            userMortgageSolicitorId
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
const getUserMortgageForApp1 = /* GraphQL */ `
    query GetUserMortgage($id: ID!) {
        getUserMortgage(id: $id) {
            id
            twoApplicants
            mortgageType
            applicationType
            applicant1ID
            applicant2ID
            submissions {
                items {
                    id
                    lenderCode
                    provisionalProduct
                    provisionalRateId
                    provisionalRate
                    provisionalSecondaryProduct
                    provisionalSecondaryRate
                    provisionalSecondaryRateId
                    soft
                    aipMaxLoan
                    aipPropertyReference
                    aipExpiryDate
                    aipDate
                    loanOfferDate
                    loanOfferExpiryDate
                    drawdownDate
                    completedFlowItems
                    reinstatementValue
                    valuationInstructed
                    valuationDate
                    valuationExpiryDate
                    anotherValuationNeeded
                    valuation2Instructed
                    valuation2Date
                    valuation2ExpiryDate
                    reinstatement2Value
                    valuation2Value
                    surveyRequired
                    surveyDate
                    mortgageAccountNo
                    mortgageID
                    invalidatedReason
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
                __typename
            }
            appointments {
                items {
                    id
                    backendUserID
                    appointmentType
                    appointmentDate
                    mortgageID
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                    __typename
                }
                nextToken
                startedAt
                __typename
            }
            requirement {
                id
                applicationElsewhere
                loanRequired
                cohabiting
                loanTerm
                firstTimeBuyers
                depositSavings
                depositGifts
                helpToBuyScheme
                helpToBuySchemeAmount
                firstHomeScheme
                firstHomeSchemeCouncil
                firstHomeSchemeType
                firstHomeSchemeAmount
                lenderCode
                rateId
                lenderProduct
                receivingGifts
                drawdownDate
                aipExpiryDate
                loanOfferDate
                propertyReference
                mortgageAccountNo
                contactMethod
                proposals
                soft
                createdAt
                updatedAt
                _version
            }
            needs {
                id
                circumstanceChanges
                circumstanceChangesDetails
                healthIssues
                healthIssuesDetails
                policeRecord
                policeRecordDetails
                createdAt
                updatedAt
                _version
            }
            properties {
                items {
                    id
                    addressKnown
                    address
                    propertyType
                    buildType
                    newlyBuilt
                    bedrooms
                    bathrooms
                    berScore
                    propertyValue
                    propertyPrice
                    origPurchasePrice
                    yearOfPurchase
                    residingHere
                    adultsBoarding
                    adultsBoardingCount
                    isRented
                    monthlyRent
                    isMortgaged
                    remainingTerm
                    lender
                    accountNumber
                    monthlyPayment
                    currentRate
                    outstanding
                    reinstatementValue
                    valuationDate
                    valuationInstructed
                    mortgageID
                    siteOwned
                    siteValue
                    constructionCosts
                    constructionStarted
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            uploads {
                items {
                    id
                    contentType
                    fileName
                    fileSize
                    fileExtension
                    stage
                    section
                    index
                    status
                    mortgageID
                    reason
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            progress {
                items {
                    id
                    applicationStage
                    sectionType
                    sectionName
                    sectionIndex
                    percent
                    mortgageID
                    percentUpdatedAt
                    alertTitle
                    alertBody
                    alertAuthor
                    alertDismissed
                    alertDone
                    alertPending
                    sectionChecked
                    sectionCheckedBy
                    submittedOn
                    submittedBy
                    expiresDate
                    note
                    lastDocumentDate
                    lastDocumentDateDismissed
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            applicant1 {
                id
                subjectId
                identityID
                email
                mobile
                firstName
                surname
                isMale
                lastHere
                userApplicantPersonalId
                userApplicantIncomeId
                userApplicantFinancialId
                userApplicantEmploymentId
                userApplicantTaxId
                userApplicantLastHereId
                data
                employmentIncome {
                    items {
                        id
                        applicantID
                        employmentType
                        employmentBasis
                        positionHeld
                        currentEmployerName
                        currentEmployerAddress
                        currentEmployerPhone
                        currentEmployerContact
                        currentEmployerStartDate
                        previousEmployerName
                        firstJob
                        previousEmployerAddress
                        previousPositionHeld
                        previousEmploymentBasis
                        previousEmployerStartDate
                        previousEmployerEndDate
                        nameOfBusiness
                        natureOfBusiness
                        businessAddress
                        accountantName
                        accountantAddress
                        accountantPhone
                        businessEstablished
                        limitedCompany
                        isDirector
                        percentageOfShares
                        selfIncomeSource
                        grossBasic
                        nonEuroCurrency
                        incomes
                        publicSector
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                health {
                    id
                    weight
                    height
                    smoker
                    everSmoked
                    stoppedSmokingDate
                    alcoholUnits
                    dangerousActivities
                    familyDeath
                    diagnosedConditions
                    diagnosedConditionsDetails
                    prescribedMedication
                    prescribedMedicationDetails
                    hasGp
                    gpName
                    gpAddress
                    gpLastVisit
                    gpLastVisitYearsAgo
                    yearsWithGp
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                accounts {
                    items {
                        id
                        joint
                        isClosed
                        institution
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                personal {
                    id
                    title
                    dateOfBirth
                    maritalStatus
                    maidenName
                    mothersMaidenName
                    nationality
                    countryOfBirth
                    placeOfBirth
                    countryOfResidence
                    countDependents
                    dependentDOBs
                    accommodationSituation
                    accommodationSituationOther
                    addressCurrent
                    addressCurrentYears
                    addressCurrentStartDate
                    addressPrevious
                    irishResidenceStartDate
                    otherCountryOfResidenceInPast3Years
                    createdAt
                    updatedAt
                    _version
                }
                income {
                    id
                    grossBasic
                    hasOvertime
                    overtime
                    hasBonus
                    bonus
                    hasCommission
                    commission
                    hasOther
                    other
                    otherSource
                    otherSourceDetails
                    nonEuroCurrency
                    maintenance
                    welfare
                    secondjob
                    rentalIncome
                    dividends
                    selfIncomeSource
                    salariedInstitution
                    incomeChecks
                    createdAt
                    updatedAt
                    _version
                }
                financial {
                    id
                    hasAccommodationCosts
                    monthlyAccommodationCosts
                    hasChildcareCosts
                    contributeToRelatives
                    negativeCredit
                    hasAssets
                    hasDebts
                    hasIncome
                    hasProperties
                    hasLoans
                    hasSavings
                    hasCurrentAccountSavings
                    paysMaintenance
                    maintenanceCosts
                    monthlyChildcareCosts
                    monthlySavings
                    createdAt
                    updatedAt
                    _version
                }
                employment {
                    id
                    employmentType
                    currentEmployerName
                    currentEmployerAddress
                    currentEmployerPhone
                    currentEmployerYearsThere
                    currentEmployerContact
                    previousEmployerName
                    previousEmployerAddress
                    previousEmployerYearsThere
                    positionHeld
                    nameOfBusiness
                    natureOfBusiness
                    businessAddress
                    accountantName
                    accountantAddress
                    accountantPhone
                    businessEstablished
                    employmentBasis
                    limitedCompany
                    isDirector
                    percentageOfShares
                    currentEmployerStartDate
                    createdAt
                    updatedAt
                    _version
                }
                tax {
                    id
                    irishTaxResident
                    ppsNumber
                    usCitizen
                    usTaxId
                    otherTaxResident
                    otherTaxCountry
                    otherTaxId
                    createdAt
                    updatedAt
                    _version
                }
                assets {
                    items {
                        id
                        joint
                        isClosed
                        assetType
                        institution
                        description
                        balance
                        increasesFrequency
                        increases
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                properties {
                    items {
                        id
                        propertyType
                        address
                        estimatedValue
                        isRented
                        rentPerAnnum
                        isMortgaged
                        outstanding
                        monthlyRepayments
                        remainingTerm
                        lender
                        inArrears
                        arrears
                        applicantID
                        bedrooms
                        bathrooms
                        isRestructured
                        observeMID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                debts {
                    items {
                        id
                        debtType
                        lender
                        outstanding
                        monthlyPayment
                        creditLimit
                        inArrears
                        arrears
                        endDate
                        clearedBeforeMortgage
                        applicantID
                        accountNumber
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                activity {
                    items {
                        id
                        actions
                        actionedOn
                        actionedBy
                        applicantID
                        userAgent
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _deleted
                        _version
                    }
                    nextToken
                }
                lastHereTime{
                    id
                    time
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _deleted
                    _version
                }
                createdAt
                updatedAt
                _version
            }
            applicant2 {
                id
                subjectId
                identityID
                email
                mobile
                firstName
                surname
                isMale
                lastHere
                userApplicantPersonalId
                userApplicantIncomeId
                userApplicantFinancialId
                userApplicantEmploymentId
                userApplicantTaxId
                data
                employmentIncome {
                    items {
                        id
                        applicantID
                        employmentType
                        employmentBasis
                        positionHeld
                        currentEmployerName
                        currentEmployerAddress
                        currentEmployerPhone
                        currentEmployerContact
                        currentEmployerStartDate
                        firstJob
                        previousEmployerName
                        previousEmployerAddress
                        previousPositionHeld
                        previousEmploymentBasis
                        previousEmployerStartDate
                        previousEmployerEndDate
                        nameOfBusiness
                        natureOfBusiness
                        businessAddress
                        accountantName
                        accountantAddress
                        accountantPhone
                        businessEstablished
                        limitedCompany
                        isDirector
                        percentageOfShares
                        selfIncomeSource
                        grossBasic
                        nonEuroCurrency
                        incomes
                        publicSector
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                health {
                    id
                    weight
                    height
                    smoker
                    everSmoked
                    stoppedSmokingDate
                    alcoholUnits
                    dangerousActivities
                    familyDeath
                    diagnosedConditions
                    diagnosedConditionsDetails
                    prescribedMedication
                    prescribedMedicationDetails
                    hasGp
                    gpName
                    gpAddress
                    gpLastVisit
                    gpLastVisitYearsAgo
                    yearsWithGp
                    readAccessGroup
                    editAccessGroup
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                accounts {
                    items {
                        id
                        joint
                        isClosed
                        institution
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                personal {
                    id
                    title
                    dateOfBirth
                    maritalStatus
                    maidenName
                    mothersMaidenName
                    nationality
                    countryOfBirth
                    placeOfBirth
                    countryOfResidence
                    countDependents
                    dependentDOBs
                    accommodationSituation
                    accommodationSituationOther
                    addressCurrent
                    addressCurrentYears
                    addressCurrentStartDate
                    addressPrevious
                    irishResidenceStartDate
                    otherCountryOfResidenceInPast3Years
                    createdAt
                    updatedAt
                    _version
                }
                income {
                    id
                    grossBasic
                    hasOvertime
                    overtime
                    hasBonus
                    bonus
                    hasCommission
                    commission
                    hasOther
                    other
                    otherSource
                    otherSourceDetails
                    nonEuroCurrency
                    maintenance
                    welfare
                    secondjob
                    rentalIncome
                    dividends
                    selfIncomeSource
                    salariedInstitution
                    incomeChecks
                    createdAt
                    updatedAt
                    _version
                }
                financial {
                    id
                    hasAccommodationCosts
                    monthlyAccommodationCosts
                    hasChildcareCosts
                    contributeToRelatives
                    negativeCredit
                    hasAssets
                    hasDebts
                    hasIncome
                    hasProperties
                    hasLoans
                    hasSavings
                    hasCurrentAccountSavings
                    paysMaintenance
                    maintenanceCosts
                    monthlyChildcareCosts
                    monthlySavings
                    createdAt
                    updatedAt
                    _version
                }
                employment {
                    id
                    employmentType
                    currentEmployerName
                    currentEmployerAddress
                    currentEmployerPhone
                    currentEmployerYearsThere
                    currentEmployerContact
                    previousEmployerName
                    previousEmployerAddress
                    previousEmployerYearsThere
                    positionHeld
                    nameOfBusiness
                    natureOfBusiness
                    businessAddress
                    accountantName
                    accountantAddress
                    accountantPhone
                    businessEstablished
                    employmentBasis
                    limitedCompany
                    isDirector
                    percentageOfShares
                    currentEmployerStartDate
                    createdAt
                    updatedAt
                    _version
                }
                tax {
                    id
                    irishTaxResident
                    ppsNumber
                    usCitizen
                    usTaxId
                    otherTaxResident
                    otherTaxCountry
                    otherTaxId
                    createdAt
                    updatedAt
                    _version
                }
                assets {
                    items {
                        id
                        joint
                        isClosed
                        assetType
                        institution
                        description
                        balance
                        increasesFrequency
                        increases
                        applicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                properties {
                    items {
                        id
                        propertyType
                        address
                        estimatedValue
                        isRented
                        rentPerAnnum
                        isMortgaged
                        outstanding
                        monthlyRepayments
                        remainingTerm
                        lender
                        inArrears
                        arrears
                        applicantID
                        bedrooms
                        bathrooms
                        isRestructured
                        observeMID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                debts {
                    items {
                        id
                        debtType
                        lender
                        outstanding
                        monthlyPayment
                        creditLimit
                        inArrears
                        arrears
                        endDate
                        clearedBeforeMortgage
                        applicantID
                        accountNumber
                        createdAt
                        updatedAt
                        _version
                        _deleted
                    }
                    nextToken
                }
                activity {
                    items {
                        id
                        actions
                        actionedOn
                        actionedBy
                        applicantID
                        userAgent
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _deleted
                    }
                    nextToken
                }
                createdAt
                updatedAt
                _version
            }
            applicationStage
            applicationStatus
            queries {
                items {
                    id
                    mortgageID
                    referenceType
                    referenceId
                    section
                    index
                    stage
                    title
                    date
                    description
                    amount
                    query
                    userAnswer
                    userAnswerOn
                    userAnswerBy
                    adminAnswered
                    userAnswered
                    private
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            solicitor {
                id
                firmName
                firmAddress
                contactName
                contactEmail
                contactPhone
                createdAt
                updatedAt
                _version
            }
            dynamicSections {
                items {
                    id
                    sectionStage
                    sectionType
                    sectionName
                    sectionIndex
                    sectionTitle
                    sectionInstructions
                    mortgageID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                }
                nextToken
            }
            invitations {
                items {
                    id
                    mortgageID
                    applicant2ID
                    firstName
                    email
                    inviter
                    inviterFirstName
                    sent
                    accepted
                    invalidated
                }
                nextToken
            }
            completedFlowItems
            qualification
            advisorID
            UserMortgageDocuments {
                items {
                    id
                    title
                    description
                    folder
                    fileName
                    identityID
                    identityID2
                    fileExtension
                    isDynamic
                    stage
                    mortgageID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
            }
            userMortgageRequirementId
            userMortgageSolicitorId
            userMortgageNeedsId
            createdAt
            _version
            _lastChangedAt
        }
    }
`;
async function getMortgage (id) {
    const variables = {id}
    try{
        const session = await Auth.currentSession()
        const payload = session && session.getIdToken().payload
        if (payload.hasOwnProperty('custom:invitationId')) {
            return performGQL(variables, getUserMortgageForApp2)
        }
        return performGQL(variables, getUserMortgageForApp1)
    }
    catch (e) {
        console.log(e)
    }
}
export default getMortgage